import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { StyleOrStyleArray, Style } from '@glitz/type';
import Link from 'Shared/Link';
import { translate } from '@avensia/scope';
import { epiPropertyValue } from '@avensia/scope-episerver';
import EsalesProductForListType from '../../../Esales/Models/EsalesProductForList.type';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';
import UspBullets from './../ProductDetails/UspBullets';
import {
  gamma,
  theta,
  transition,
  pixelsToUnit,
  truncate,
  small,
  minMediumMediaQuery,
  zeta,
  sigma,
} from 'Shared/Style';
import connect from 'Shared/connect';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import Icon from 'Shared/Icon/Cart';
import { formatTitleCase } from 'Shared/string-format';
import AddToCart from 'Product/ProductAddToCart';
import AverageRatings, { Spacing, Size } from 'Product/ProductDetails/AverageRating';
import AverageRatingsBlack from 'Product/ProductDetails/AverageRatingBlack';
import Price, { Type as PriceType } from 'Pricing/Price';
import { NewBadge, DiscountBadge, DiscountTriBadge } from './Badge';
import { prepareVariationName } from 'Shared/variation-helper';
import { Check } from 'Shared/Icon/Check-Thin';
import Cross from 'Shared/Icon/RemovePlain';
import { Theme } from 'Shared/Theming';

type RequiredPropType = {
  product: EsalesProductForListType;
  addToCart: () => Promise<void>;
};

type StateType = {
  bulletPointsVisible: boolean;
};

type ConnectStateType = {
  theme: string;
};

type PropType = RequiredPropType & StyledProps & ConnectStateType;

class ProductCard extends React.Component<PropType, StateType> {
  state: StateType = {
    bulletPointsVisible: false,
  };

  toggleBulletPointsTrue = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ bulletPointsVisible: true });
    event.stopPropagation();
    event.preventDefault();
  };

  toggleBulletPointsFalse = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ bulletPointsVisible: false });
    event.stopPropagation();
    event.preventDefault();
  };

  render() {
    const {
      averageRating: productRatings = 0,
      brand = '',
      imageUrls,
      price,
      hasMultipleVariants = false,
      hasDiscount = false,
      hasPromotion = false,
      isNew: isProductNew = false,
      variation: { displayName },
      url: productUrl = '',
      inStock,
      ticket: productTicket,
      displayTagDesigner,
      displayHighlightImage,
      whiteHighlightText,
      highlightImageUrl,
      hideProductInfo,
      bulletPoints = [],
      promotions,
    } = this.props.product;

    const { bulletPointsVisible } = this.state;

    const images = imageUrls || [];
    const productImage = images.length > 0 ? images[0] : '';
    const productBrand = brand ? formatTitleCase(brand.trim()) : '';
    const productName = formatTitleCase(prepareVariationName(epiPropertyValue(displayName)).trim());
    const { current: currentPrice, original: originalPrice, currency, discountPercentage } = price;
    const isPriceDiscounted = currentPrice < originalPrice;
    const currentTheme = this.props.theme;
    const imageHoverStyle: Style = {
      height: '110px',
    };

    const imageNoHoverStyle: Style = {
      height: '200px',
    };

    const imageWithPaddingStyle: Style = {
      paddingTop: pixelsToUnit(10),
    };

    const actionButtonText =
      hasMultipleVariants || displayTagDesigner
        ? translate('/ProductListing/ViewProduct')
        : inStock
        ? this.props.theme === Theme.DoggieDefault
          ? translate('/Cart/BuyDoggie')
          : translate('/Cart/BuyDesktop')
        : translate('/Cart/OutOfStock');

    const PromotionNode = (
      <styled.Div css={{ position: 'absolute', right: 0, zIndex: 4 }}>
        <PromotionTitle>{translate('/Product/Badge/Campaign')}</PromotionTitle>
        {promotions.map((promotion, i) => {
          if (i < 4) {
            return (
              <PromotionLink key={i} to={promotion.promotionUrl}>
                <PromotionItemTitle>{promotion.promotionName}</PromotionItemTitle>
              </PromotionLink>
            );
          }
        })}
      </styled.Div>
    );

    return (
      <Base css={this.props.compose()}>
        {hasPromotion && PromotionNode}
        <ExtensionWrapper onMouseEnter={this.toggleBulletPointsTrue} onMouseLeave={this.toggleBulletPointsFalse}>
          <Body to={productUrl} linkIdentifier={productTicket}>
            {displayHighlightImage && (
              <HighlightImage>
                <img style={{ height: '100%', objectFit: 'cover' }} src={highlightImageUrl} />
              </HighlightImage>
            )}
            <Wrapper
              css={
                displayHighlightImage &&
                (hideProductInfo
                  ? { visibility: 'hidden' }
                  : whiteHighlightText
                  ? { color: 'white', zIndex: 4 }
                  : { zIndex: 4 })
              }
            >
              {hasDiscount ? (
                !currentTheme.startsWith('dog') &&
                !currentTheme.startsWith('cli') &&
                !currentTheme.startsWith('lac') ? (
                  <DiscountTriBadge>{`-${discountPercentage}%`}</DiscountTriBadge>
                ) : (
                  <DiscountBadge>{`-${discountPercentage}%`}</DiscountBadge>
                )
              ) : (
                isProductNew && <NewBadge>{`${translate('/Product/Badge/New')}!`}</NewBadge>
              )}
              {!displayHighlightImage ? (
                <ImageStyled
                  alt={productName}
                  itemProp="image"
                  preset={!productImage.includes('.gif') ? ImagePreset.Tiny : undefined}
                  ratio={ImageRatio.OneToOne}
                  src={productImage}
                  css={bulletPointsVisible && bulletPoints.length > 0 ? imageHoverStyle : imageNoHoverStyle}
                  imageStyle={imageWithPaddingStyle}
                  lazy
                />
              ) : (
                <div style={{ height: '200px' }} />
              )}

              <RatingsPlaceholder>
                {!!productRatings ? (
                  !currentTheme.startsWith('dog') &&
                  !currentTheme.startsWith('cli') &&
                  !currentTheme.startsWith('lac') ? (
                    <AverageRatingsBlack average={productRatings} spacing={Spacing.Normal} size={Size.Tiny} />
                  ) : (
                    <Ratings average={productRatings} spacing={Spacing.Normal} size={Size.Normal} />
                  )
                ) : (
                  <RatingsNone average={productRatings} spacing={Spacing.Normal} size={Size.Normal} />
                )}
              </RatingsPlaceholder>
              <BrandPlaceholder>{productBrand && <Brand itemProp="brand">{productBrand}</Brand>}</BrandPlaceholder>
              <NameWrapper css={displayHighlightImage && { zIndex: 4 }}>
                <Name itemProp="name">{productName}</Name>
              </NameWrapper>
              {!displayHighlightImage && bulletPointsVisible && (
                <UspBulletsStyled
                  bulletLimit
                  hasValue={bulletPoints && bulletPoints.length > 0}
                  bulletPoints={bulletPoints}
                  listItemCss={bulletListItemStyle}
                />
              )}
            </Wrapper>
          </Body>
          <LowerWrapper>
            <Prices>
              <CurrentPrice
                css={whiteHighlightText && displayHighlightImage && { color: 'white' }}
                current={currentPrice}
                currency={currency}
                priceType={isPriceDiscounted ? PriceType.New : PriceType.Regular}
              />
              {isPriceDiscounted && <OldPrice current={originalPrice} currency={currency} priceType={PriceType.Old} />}
            </Prices>
            <StockPlaceholder>
              {inStock ? (
                <StockStatus>
                  <CheckIcon />
                  {translate('/Product/InStock')}
                </StockStatus>
              ) : (
                <StockStatus>
                  {translate('/Product/OutOfStock')}
                  <CrossIcon />
                </StockStatus>
              )}
            </StockPlaceholder>
            <ActionStyled
              displayTagDesigner={displayTagDesigner}
              css={displayHighlightImage ? { zIndex: 4 } : { zIndex: 0 }}
              hasVariants={hasMultipleVariants}
              productUrl={productUrl}
              isInStock={inStock}
              addCart={this.props.addToCart}
            >
              {inStock && <CartIcon />}
              {actionButtonText}
            </ActionStyled>
          </LowerWrapper>
        </ExtensionWrapper>
      </Base>
    );
  }
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      theme: state.currentTheme,
    }),
  )(ProductCard),
);

type ActionPropType = {
  hasVariants: boolean;
  productUrl: string;
  isInStock: boolean;
  children: React.ReactNode;
  addCart: () => Promise<void>;
  displayTagDesigner: boolean;
};

const Action = styled((props: ActionPropType & StyledProps) => {
  return props.hasVariants || props.displayTagDesigner ? (
    <ViewProduct
      css={props.compose()}
      to={props.productUrl}
      variant={ButtonVariant.Small}
      appearance={[ButtonAppearance.Primary, ButtonAppearance.Full]}
    >
      {props.children}
    </ViewProduct>
  ) : (
    <AddCart css={props.compose()} disabled={!props.isInStock} variant={ButtonVariant.Small} addToCart={props.addCart}>
      {props.children}
    </AddCart>
  );
});

const Base = styled.div({
  height: '400px',
  boxShadow: (theme) => `0 0.2rem 1rem 0 rgba(${theme.shadowColor}, 0.2)`,
  display: 'flex',
  backgroundColor: (theme) => theme.cardBackgroundColor,
  flexDirection: 'column',
  position: 'relative',
  textAlign: 'left',
  marginBottom: '2rem',
});

const Wrapper = styled.div({
  position: 'relative',
});

const ExtensionWrapper = styled.div({
  position: 'relative',
  height: '400px',
  overflow: 'hidden',
  backgroundColor: (theme) => theme.cardBackgroundColor,
  borderRadius: '0.15rem',
  width: '100%',
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: (theme) => `0 0 0.5rem rgba(${theme.shadowColor}, 0)`,
  transform: 'translateZ(0)',
  willChange: 'transform',
  ...transition({
    property: ['color', 'background', 'opacity', 'box-shadow', 'transform'],
    duration: '0.2s',
    timingFunction: 'ease-out',
  }),
  ':hover': {
    backgroundColor: '#fff',
    boxShadow: (theme) => `0 0.3rem 1rem 0 rgba(${theme.shadowColor}, 0.3)`,
    transform: 'translateZ(0) scale(1.02)',
    zIndex: 3,
  },
});

const ActionStyled = styled(Action, {
  backgroundColor: (theme) => theme.buyButtonProductcardDesktop,
});

const StockPlaceholder = styled.div({
  padding: {
    x: pixelsToUnit(5),
  },
});

const StockStatus = styled.span({
  fontSize: pixelsToUnit(11),
  letterSpacing: pixelsToUnit(0.3),
  lineHeight: pixelsToUnit(10),
});

const CheckIcon = styled(Check, {
  marginRight: pixelsToUnit(5),
  height: '1rem',
  color: (theme) => theme.buttonTextColor,
});

const Body = styled(Link, {
  color: (theme) => theme.textColor,
  marginBottom: pixelsToUnit(10),
});

const Brand = styled.div({
  fontSize: sigma,
  marginBottom: small,
  ...truncate(),
});

const PromotionLink = styled(Link, {
  marginBottom: pixelsToUnit(10),
  cursor: 'pointer',
});

const BrandPlaceholder = styled.div({
  height: small,
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: {
    basis: pixelsToUnit(18),
    shrink: 0,
  },
  lineHeight: 'normal',
  marginTop: pixelsToUnit(10),
  padding: {
    x: pixelsToUnit(5),
  },
});

const Ratings = styled(AverageRatings, {
  lineHeight: pixelsToUnit(18),
});

const RatingsNone = styled(Ratings, {
  opacity: 0.3,
});

const RatingsPlaceholder = styled.div({
  paddingLeft: pixelsToUnit(5),
  marginTop: pixelsToUnit(10),
  marginBottom: pixelsToUnit(8),
});

const NameWrapper = styled.div({
  height: '2.4rem',
  padding: {
    x: pixelsToUnit(5),
  },
});

const Name = styled.div({
  position: 'relative',
  flexBasis: pixelsToUnit(34),
  textTransform: 'uppercase',
  fontWeight: 'bold',
  flexShrink: 0,
  fontSize: zeta,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  letterSpacing: pixelsToUnit(0.2),
  lineHeight: 'normal',
  marginTop: pixelsToUnit(3),
  [minMediumMediaQuery]: {
    flexBasis: pixelsToUnit(46),
    fontSize: sigma,
    marginTop: pixelsToUnit(3),
  },
});

const bulletListItemStyle: StyleOrStyleArray = {
  fontSize: '0.7rem',
  marginBottom: 0,
  marginLeft: '0.2rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  [minMediumMediaQuery]: {
    fontSize: '0.8rem',
  },
};

const Prices = styled.div({
  display: 'flex',
  flexBasis: pixelsToUnit(20),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexShrink: 0,
  justifyContent: 'flex-start',
  padding: {
    x: pixelsToUnit(5),
  },
  margin: {
    top: pixelsToUnit(8),
    bottom: pixelsToUnit(8),
  },
});

const CurrentPrice = styled(Price, {
  lineHeight: '1rem',
  fontSize: gamma,
  color: (theme) => theme.textColor,
  marginRight: small,
});

const OldPrice = styled(Price, {
  paddingTop: '0.15rem',
  fontSize: theta,
  lineHeight: '0.8rem',
});

const UspBulletsStyled = styled(UspBullets, {
  ...transition({ property: 'all', duration: 150 }),
  padding: {
    x: pixelsToUnit(5),
    bottom: pixelsToUnit(5),
  },
  margin: {
    xy: 0,
  },
});

const CartIcon = styled(Icon, {
  width: pixelsToUnit(19.3),
  height: pixelsToUnit(18),
  [minMediumMediaQuery]: {
    marginRight: pixelsToUnit(10),
  },
});

const CrossIcon = styled(Cross, {
  marginLeft: pixelsToUnit(5),
  height: '1rem',
  color: 'red',
});

const actionStyle = styled({
  fontSize: theta,
  borderRadius: 0,
  padding: {
    x: pixelsToUnit(5),
  },
  ...transition({ property: ['opacity', 'background'], duration: '1s' }),
});

const ViewProduct = actionStyle(Button);
const AddCart = actionStyle(AddToCart);

const ImageStyled = styled(Image, {
  mixBlendMode: 'multiply',
  ...transition({ property: 'all', duration: 200 }),
});

const HighlightImage = styled.div({
  color: (theme) => theme.campaignBackgroundColor,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 3,
  height: '100%',
  width: '100%',
});

const LowerWrapper = styled.div({
  backgroundColor: (theme) => theme.cardBackgroundColor,
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '95px',
});

export const PromotionItemTitle = styled.div({
  color: 'white',
  backgroundColor: (theme) => theme.campaignTextProductcard,
  maxWidth: pixelsToUnit(90),
  minWidth: pixelsToUnit(60),
  width: '100%',
  fontSize: pixelsToUnit(8),
  marginBottom: pixelsToUnit(1),
  textTransform: 'uppercase',
  padding: { xy: pixelsToUnit(2) },
  textAlign: 'center',
});
export const PromotionTitle = styled(PromotionItemTitle, {
  cursor: 'default',
});
