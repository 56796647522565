import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { isIOS } from '@avensia/scope';
import { transition } from 'Shared/Style';

type PropType = StyledProps;

type State = {
  translateX?: number;
};

type SlidePropType = {
  isCurrent?: boolean;
};

export class Slide extends React.Component<SlidePropType> {
  ref = React.createRef<HTMLDivElement>();
  offsetLeft() {
    return this.ref.current.offsetLeft;
  }
  isCurrent() {
    return this.props.isCurrent;
  }
  render() {
    return (
      <styled.Div
        css={{
          minWidth: '100%',
          ...(isIOS() && {
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
          }),
        }}
        ref={this.ref}
      >
        {this.props.children}
      </styled.Div>
    );
  }
}

class Slides extends React.Component<PropType, State> {
  current: Slide;
  constructor(props: PropType) {
    super(props);
    this.state = {
      translateX: 0,
    };
  }
  componentDidMount() {
    this.calculateTranslateX();
  }
  componentDidUpdate() {
    this.calculateTranslateX();
  }
  calculateTranslateX() {
    const translateX = this.current ? -this.current.offsetLeft() : 0;
    if (this.state.translateX !== translateX) {
      this.setState({
        translateX,
      });
    }
  }
  render() {
    const { children } = this.props;
    const { translateX } = this.state;
    return (
      <Base css={this.props.compose()}>
        <Container
          css={{
            transform: `translate3d(${translateX}px,0,0)`,
          }}
        >
          {React.Children.map(
            children,
            (child) =>
              React.isValidElement<any>(child) &&
              React.cloneElement(child, {
                ref: (c: React.ReactElement<any> | Slide) => {
                  if (c && c instanceof Slide && c.isCurrent()) {
                    this.current = c;
                  }
                },
              }),
          )}
        </Container>
      </Base>
    );
  }
}

export default styled(Slides);

const Base = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  overflowY: 'hidden',
});

const Container = styled.div({
  display: 'flex',
  minHeight: '100%',
  ...transition({ property: 'transform', duration: '300ms' }),
});
