import React from 'react';
import Facet from './Facet';
import FacetType from '../../../Esales/Models/Facet.type';
import FacetValueType from '../../../Esales/Models/FacetValue.type';
import toggleFacet from './toggle-facet';
import Ul from '../Generic/Ul';

type PropType = {
  facets: FacetType[];
  onFacetValueChange: (facets: FacetType[], ticket: string) => void;
};

export default class FacetList extends React.Component<PropType> {
  onFacetValueChange = (fieldName: string, facetValue: FacetValueType, selected: boolean) => {
    const facets = toggleFacet(this.props.facets, fieldName, facetValue, selected);
    if (facets !== this.props.facets) {
      this.props.onFacetValueChange(facets, selected ? facetValue.ticket : null);
    }
  };

  render() {
    return (
      <Ul column>
        {this.props.facets.map((facet, index) => (
          <Facet key={facet.fieldName} {...facet} onFacetValueChange={this.onFacetValueChange} />
        ))}
      </Ul>
    );
  }
}
