import * as clone from 'clone';
import FacetType from '../../../Esales/Models/Facet.type';
import FacetValueType from '../../../Esales/Models/FacetValue.type';

export default (facets: FacetType[], fieldName: string, facetValue: FacetValueType, selected: boolean) => {
  const newFacets = clone(facets);

  const facet = newFacets.find(f => f.fieldName === fieldName);
  if (!facet) {
    return facets;
  }
  // The path contains an array of values where the last element is the current
  const path = findValuePath(facet.facetValues, facetValue.fieldName, facetValue.id);
  const current = path[path.length - 1];
  if (!current || current.selected === selected) {
    return facets;
  }

  current.selected = selected;
  setChildSelections(current.children, selected);

  if (!selected) {
    path.forEach(v => (v.selected = false));
  }

  if (selected) {
    for (let i = path.length - 1; i >= 0; i--) {
      const value = path[i];
      if (value.children.every(c => c.selected)) {
        value.selected = true;
      }
    }
  }

  return newFacets;
};

function findValuePath(
  values: FacetValueType[],
  fieldName: string,
  id: string,
  path: FacetValueType[] = [],
): FacetValueType[] {
  const value = values.find(v => v.id === id && v.fieldName === fieldName);
  if (value) {
    path.push(value);
    return path;
  }

  for (const value of values) {
    path = findValuePath(value.children, fieldName, id);
    if (path) {
      path.unshift(value);
      return path;
    }
  }
}

export function allSelectedValues(values: FacetValueType[], selectedValues: FacetValueType[] = []) {
  selectedValues.push(...values.filter(v => v.selected));
  values.forEach(v => allSelectedValues(v.children, selectedValues));
  return selectedValues;
}

function setChildSelections(children: FacetValueType[], selected: boolean) {
  children.forEach(c => {
    c.selected = selected;
    setChildSelections(c.children, selected);
  });
}
