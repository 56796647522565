import { epiPropertyValue } from '@avensia/scope-episerver';

// This function processes a variation name so it'll display nicer.
export function prepareVariationName(name: Scope.Property<string> | string): string {
  if (typeof name !== 'string') {
    name = epiPropertyValue(name) || '';
  }

  // don't linebreak between an amount and a single-word (presumed) unit
  name = name.replace(/\d \w*$/, m => m.replace(' ', String.fromCharCode(160))); // replacing with a non-breaking space

  return name;
}
