import React from 'react';
import { styled } from '@glitz/react';
import { Style } from '@glitz/type';
import { currentUrl, replaceState, HistoryOptions, translate, addUserLog } from '@avensia/scope';
import { Basic, Part, Layout as PageLayout } from 'Shared/PageLayout';
import { bindInfiniteScroll } from 'Shared/infinite-scroll';
import Select from 'Shared/Fields/Select';
import Gallery from '../ProductCard/List';
import FacetList from 'Shared/Facet/FacetList';
import { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';
import ProductsListingViewModelType from './IProductsListingViewModel.type';
import EsalesProductForList from '../../../Esales/Models/EsalesProductForList.type';
import FacetType from '../../../Esales/Models/Facet.type';
import { allSelectedValues } from 'Shared/Facet/toggle-facet';
import { huge, large, pixelsToUnit, delta, epsilon, small, transition } from 'Shared/Style';
import Viewport from 'Shared/Viewport';
import CompactFilterSort from './CompactFilterSort';
import SubMenu from 'Shared/SubMenu';
import NavigationItem from 'Shared/NavigationItem/NavigationItem.type';
import FacetValueType from '../../../Esales/Models/FacetValue.type';
import toggleFacet from 'Shared/Facet/toggle-facet';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import CategoryHits from 'Search/CategoryHits';
import EsalesCategory from '../../../Esales/Models/EsalesCategory.type';
import connect from 'Shared/connect';

type RequiredPropType = ProductsListingViewModelType & {
  queryParams?: string;
  isSearchPage?: boolean;
  subMenu?: NavigationItem[];
  topContent?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
  pageTitle: React.ReactNode;
  className?: string;
};

type ConnectStateType = {
  theme: string;
};

type PropType = RequiredPropType &
  ConnectStateType &
  ConnectPropType & {
    searchCategories?: EsalesCategory[];
  };

type StateType = {
  facets?: FacetType[];
  sortBy?: string;
  infinityScroll?: boolean;
  isOpenCategoryHits?: boolean;
  isFilterPanelOpen?: boolean;
  isProductsListingFullScreen?: boolean;
};

class ProductsListing extends React.PureComponent<PropType, StateType> {
  currentlyReloading: boolean;
  unbindInfiniteScroll: () => void;
  gallery: HTMLDivElement;

  constructor(props: PropType) {
    super(props);

    this.state = {
      facets: props.products.facets,
      sortBy: props.products.sortOptions.value,
      infinityScroll: false,
      isOpenCategoryHits: false,
      isFilterPanelOpen: false,
      isProductsListingFullScreen: false,
    };

    this.currentlyReloading = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropType) {
    if (this.props.products.facets !== nextProps.products.facets) {
      this.setState({
        facets: nextProps.products.facets,
      });
    }
    if (this.props.products.sortOptions.value !== nextProps.products.sortOptions.value) {
      this.setState({
        sortBy: nextProps.products.sortOptions.value,
      });
    }
  }

  componentWillUnmount() {
    this.deactivateInfinityScroll();
  }

  showMore = async () => {
    if (!this.currentlyReloading) {
      if (this.hasMore()) {
        this.currentlyReloading = true;
        const currentProductCount = this.props.products.products.length > 0 ? this.props.products.products.length : 0;
        const newCount = currentProductCount + this.props.countPerPage;
        const skip = currentProductCount;
        const reload = async () => {
          try {
            await this.reloadPage(newCount, skip, {
              hideSpinner: true,
              merge: (currentPage: ProductsListingViewModelType, newProducts: EsalesProductForList[]) => {
                const array = currentPage.products.products.concat(newProducts);
                const newPage = Object.assign({}, currentPage, {
                  products: Object.assign({}, currentPage.products, { products: array }),
                });
                return newPage;
              },
            });
            this.currentlyReloading = false;
            return Promise.resolve();
          } catch (e) {
            this.deactivateInfinityScroll();
            this.currentlyReloading = false;
            return Promise.reject(null);
          }
        };

        this.props.feedback.push(reload());
      } else {
        this.deactivateInfinityScroll();
      }
    }
  };

  hasMore() {
    return this.props.products.totalProductCount > this.props.products.products.length;
  }

  reloadPage(count: number, skip: number, loadOptions?: HistoryOptions) {
    const url = currentUrl();
    url.searchParams.set('sortBy', this.state.sortBy);
    url.searchParams.set('count', String(count));

    for (const facet of this.state.facets) {
      url.searchParams.delete(facet.fieldName);
      for (const selected of allSelectedValues(facet.facetValues)) {
        url.searchParams.append(facet.fieldName, selected.id);
      }
    }

    url.hiddenParams.set('skip', String(skip));

    return replaceState(url, loadOptions);
  }

  onFacetValueChange = (facets: FacetType[], ticket: string) => {
    addUserLog('Changed facets');
    this.setState(
      {
        facets,
      },
      () => {
        this.reloadPage(this.props.countPerPage, 0, { linkIdentifier: ticket });
      },
    );

    if (this.unbindInfiniteScroll) {
      this.unbindInfiniteScroll();
    }
  };

  resetPage(state: StateType, options?: HistoryOptions) {
    this.deactivateInfinityScroll();
    return new Promise((resolve, reject) => {
      this.setState(state, async () => {
        try {
          await this.reloadPage(this.props.countPerPage, 0, options);
          resolve();
        } catch (e) {
          reject();
        }
      });
    });
  }

  toggleFacetValue = async (
    fieldName: string,
    facetValue: FacetValueType,
    selected: boolean = !facetValue.selected,
  ) => {
    const facets = toggleFacet(this.state.facets, fieldName, facetValue, selected);
    if (facets !== this.state.facets) {
      try {
        await this.reloadFacets(facets, selected ? facetValue.ticket : null);
        return true;
      } catch (e) {
        return false;
      }
    }

    return false;
  };

  resetFacetValues = async () => {
    const facets = this.state.facets.map((facet) => ({
      ...facet,
      facetValues: facet.facetValues.map((value) => ({
        ...value,
        selected: false,
      })),
    }));
    try {
      await this.reloadFacets(facets, null);
      return true;
    } catch (e) {
      return false;
    }
  };

  reloadFacets(facets: FacetType[], ticket: string) {
    addUserLog('Changed facets');
    return this.resetPage({ facets }, { linkIdentifier: ticket });
  }

  onSortChange = async (sortBy: string) => {
    try {
      await this.resetPage({ sortBy });
      return true;
    } catch (e) {
      return false;
    }
  };

  deactivateInfinityScroll() {
    if (this.state.infinityScroll) {
      this.setState({ infinityScroll: false });
    }
    if (this.unbindInfiniteScroll) {
      this.unbindInfiniteScroll();
    }
  }

  activateInfiniteScroll = () => {
    addUserLog('Clicked show more');
    this.showMore();
    this.setState({ infinityScroll: true });
    this.unbindInfiniteScroll = bindInfiniteScroll(this.gallery, this.showMore, -500);
  };

  onCategoryHitsOpen = () => {
    this.setState({ isOpenCategoryHits: !this.state.isOpenCategoryHits });
  };

  toggleFilterMenu = () => {
    this.setState({ isFilterPanelOpen: !this.state.isFilterPanelOpen });
    setTimeout(() => {
      this.setState({ isProductsListingFullScreen: !this.state.isProductsListingFullScreen });
    }, 200);
  };

  render() {
    const filterStyle: Style = {
      transform: 'translateX(-120%)',
      ...(this.state.isFilterPanelOpen
        ? {
            transform: 'translateX(0%)',
          }
        : { transform: 'translateX(-200%)' }),
      ...transition({ property: 'all', duration: '0.5s' }),
    };

    const FeedbackButton = this.props.feedback.Button;
    return (
      <Viewport>
        {(isCompact: boolean) => (
          <React.Fragment>
            <Basic layout={this.state.isProductsListingFullScreen ? PageLayout.OneToFive : PageLayout.ZeroToOne}>
              {!isCompact
                ? this.state.isProductsListingFullScreen && (
                    <Part css={filterStyle}>
                      {this.props.subMenu && (
                        <React.Fragment>
                          <SubMenu items={this.props.subMenu} />
                        </React.Fragment>
                      )}
                      {this.props.isSearchPage && this.props.searchCategories.length > 0 && (
                        <CategoryHitsWrapper>
                          <FacetsTitle>{translate('/Shared/Assortment')}</FacetsTitle>
                          <CategoryHits categories={this.props.searchCategories} />
                        </CategoryHitsWrapper>
                      )}
                      <React.Fragment>
                        <FacetList facets={this.state.facets} onFacetValueChange={this.onFacetValueChange} />
                      </React.Fragment>
                    </Part>
                  )
                : isCompact && (
                    <CompactFilterSort
                      facets={this.state.facets}
                      sortValue={this.state.sortBy}
                      sortOptions={this.props.products.sortOptions.options}
                      onSortChange={this.onSortChange}
                      toggleFacetValue={this.toggleFacetValue}
                      resetFacetValues={this.resetFacetValues}
                    />
                  )}
              <Basic
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {this.props.breadcrumbs}
                {this.props.topContent}
                <Heading>
                  <styled.Div css={{ marginBottom: large }}>{this.props.pageTitle}</styled.Div>
                  {isCompact && this.props.isSearchPage && this.props.searchCategories.length > 0 && (
                    <CategoryHitsText onClick={this.onCategoryHitsOpen}>
                      {this.state.isOpenCategoryHits
                        ? translate('/ProductListing/CategoryHitsHidden')
                        : translate('/ProductListing/CategoryHitsShow')}
                    </CategoryHitsText>
                  )}
                  {isCompact &&
                    this.props.isSearchPage &&
                    this.state.isOpenCategoryHits &&
                    this.props.searchCategories.length > 0 && (
                      <MobileCategoryHits categories={this.props.searchCategories} />
                    )}
                  {!isCompact && (
                    <FilterAndSort>
                      <FeedbackButton
                        css={{
                          padding: {
                            x: huge,
                          },
                        }}
                        variant={ButtonVariant.Medium}
                        appearance={ButtonAppearance.Secondary}
                        onClick={this.toggleFilterMenu}
                        disabled={this.state.infinityScroll}
                      >
                        {this.state.isProductsListingFullScreen
                          ? translate('/ProductListing/HideFilter')
                          : translate('/ProductListing/ShowFilter')}
                      </FeedbackButton>
                      <Sort
                        options={this.props.products.sortOptions.options}
                        value={this.state.sortBy}
                        onChangeOption={(sortBy) => this.onSortChange(sortBy)}
                        variant={ButtonVariant.Medium}
                        appearance={[ButtonAppearance.Secondary, ButtonAppearance.Full]}
                      />
                    </FilterAndSort>
                  )}
                </Heading>
                <Products elementRef={(el) => (this.gallery = el)}>
                  <Gallery products={this.props.products.products} />
                </Products>
                <Pagination>
                  {this.hasMore() && (
                    <FeedbackButton
                      css={{
                        padding: {
                          x: huge,
                        },
                      }}
                      variant={ButtonVariant.Large}
                      appearance={ButtonAppearance.Secondary}
                      onClick={this.activateInfiniteScroll}
                      disabled={this.state.infinityScroll}
                    >
                      {translate('/ProductListing/ShowMore')}
                    </FeedbackButton>
                  )}
                </Pagination>
              </Basic>
            </Basic>
          </React.Fragment>
        )}
      </Viewport>
    );
  }
}

const Sort = styled(Select, {
  float: 'right',
  marginLeft: large,
  minWidth: pixelsToUnit(211),
  height: pixelsToUnit(45),
  zIndex: 3,
});

const Heading = styled.div({
  display: 'block',
  order: 1,
  marginBottom: large,
  marginTop: large,
});

const Products = styled(Part, {
  order: 2,
});

export default connectWithFeedback({ maximumFulfilled: 0 })(
  connect((state) => ({
    theme: state.currentTheme,
  }))(ProductsListing),
);

const Pagination = styled(Part, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  order: 3,
});

const FacetsTitle = styled.div({
  fontSize: delta,
  fontWeight: 'bold',
  marginBottom: large,
  textTransform: 'uppercase',
  letterSpacing: pixelsToUnit(2.2),
});

const CategoryHitsWrapper = styled.div({
  overflowY: 'hidden',
});

const CategoryHitsText = styled.div({
  cursor: 'pointer',
  display: 'block',
  textAlign: 'center',
  margin: {
    top: small,
    x: 'auto',
  },
  textDecoration: 'underline',
  fontSize: epsilon,
});

const FilterAndSort = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const MobileCategoryHits = styled(CategoryHits, {
  marginTop: small,
});
