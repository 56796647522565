import React from 'react';
import { translate } from '@avensia/scope';
import { styled, StyledProps } from '@glitz/react';
import Ul from 'Shared/Generic/Ul';
import Link from 'Shared/Link';
import { pixelsToUnit, sigma, thin, small, minMediumMediaQuery, boulder } from 'Shared/Style';
import EsalesCategory from '../../Esales/Models/EsalesCategory.type';

export const VISIBLE_FILTER_THRESHOLD = 5;

type PropType = {
  isCompact?: boolean;
  categories?: EsalesCategory[];
} & StyledProps;

type StateType = {
  someValuesAreHidden: boolean;
  isOpen: boolean;
};
class CategoryHits extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      someValuesAreHidden: this.props.categories.length > VISIBLE_FILTER_THRESHOLD,
      isOpen: true,
    };
  }
  toggleShowAll = () => this.setState({ someValuesAreHidden: !this.state.someValuesAreHidden });

  render() {
    const { categories } = this.props;
    const exceedVisibleCount = categories.length > VISIBLE_FILTER_THRESHOLD;
    let filters = [...categories];

    if (this.state.someValuesAreHidden) {
      filters = filters.slice(0, VISIBLE_FILTER_THRESHOLD);
    }
    return (
      <styled.Div css={this.props.compose({ marginBottom: this.state.isOpen ? pixelsToUnit(36) : 0 })}>
        <Ul column>
          {filters.map((category, index) => (
            <Li key={`${category.url + index}_${new Date().getTime()}`}>
              <Name to={category.url}>
                {category.name}
                {category.parentName && <ParentName>({category.parentName})</ParentName>}
              </Name>
            </Li>
          ))}
        </Ul>
        {exceedVisibleCount && (
          <ViewAll onClick={this.toggleShowAll}>
            {this.state.someValuesAreHidden ? translate('/Shared/ShowAll') : translate('/Shared/ShowLess')}
          </ViewAll>
        )}
      </styled.Div>
    );
  }
}

export default styled(CategoryHits);
const Li = styled.li({
  display: 'block',
  width: '100%',
});

const Name = styled(Link, {
  height: 'auto',
  lineHeight: '14px',
  color: '#4c4c4c',
  padding: {
    y: '6px',
    x: 0,
  },
  ':hover': {
    textDecoration: 'underline',
  },
  display: 'inherit',
  maxHeight: '24px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: sigma,
  [minMediumMediaQuery]: {
    maxWidth: '210px',
  },
});
const ViewAll = styled.div({
  marginTop: small,
  borderBottom: {
    width: thin,
    style: 'solid',
    color: '#5e3975',
  },
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: sigma,
});

const ParentName = styled.span({
  paddingLeft: pixelsToUnit(8),
  color: boulder,
});
