import React from 'react';
import { Style } from '@glitz/type';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import { Right } from 'Shared/Icon/Arrow';
import { Checked, Unchecked } from 'Shared/Icon/Checkbox';
import { pixelsToUnit, kappa, lightGrey, eta, epsilon, alto, truncate } from 'Shared/Style';

type SharedPropType = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

const checkboxCss: Style = {
  width: pixelsToUnit(18),
  height: pixelsToUnit(18),
};

const StyledChecked = styled(Checked, checkboxCss);
const StyledUnchecked = styled(Unchecked, checkboxCss);

const CheckedCheckbox = styled(StyledChecked, {
  color: theme => theme.secondaryColor,
});

const DisabledCheckbox = styled(StyledChecked, {
  color: lightGrey,
});

const ArrowRight = styled(Right, {
  fontSize: kappa,
});

const Box = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  padding: {
    y: 0,
    x: pixelsToUnit(20),
  },
  width: '100%',
});

const FilterButton = styled(Button, {
  borderBottom: {
    width: '1px',
    style: 'solid',
    color: alto,
  },
});

const Shared: React.StatelessComponent<SharedPropType> = props => {
  return (
    <FilterButton
      variant={ButtonVariant.Huge}
      appearance={[ButtonAppearance.Bare, ButtonAppearance.Full]}
      onClick={props.onClick}
      noRippleEffect={props.disabled}
    >
      <Box>{props.children}</Box>
    </FilterButton>
  );
};

const Content = styled.div({
  flexShrink: 1,
  lineHeight: 'normal',
  marginRight: 'auto',
  minWidth: 0,
  textAlign: 'left',
});

const Selected = styled.div({
  fontSize: eta,
  ...truncate(),
});

const Label = styled.div({
  fontSize: epsilon,
  textTransform: 'uppercase',
  ...truncate(),
});

type ItemPropType = {
  selected: string;
  label: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export function Item(props: ItemPropType) {
  return (
    <Shared onClick={props.onClick}>
      <Content>
        <Label>{props.label}</Label>
        <Selected>{props.selected || translate('/Facets/Selected/All')}</Selected>
      </Content>
      <ArrowRight />
    </Shared>
  );
}

const Value = styled.div({
  marginRight: 'auto',
  ...truncate(),
});

type ItemValuePropType = {
  selected: boolean;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export const ItemValue: React.StatelessComponent<ItemValuePropType> = props => {
  return (
    <Shared onClick={props.disabled ? e => e.preventDefault() : props.onClick}>
      <Value
        css={
          props.disabled && {
            color: lightGrey,
          }
        }
      >
        {props.children}
      </Value>
      {props.disabled ? <DisabledCheckbox /> : props.selected ? <CheckedCheckbox /> : <StyledUnchecked />}
    </Shared>
  );
};
