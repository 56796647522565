import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { StyleOrStyleArray, Style } from '@glitz/type';
import Link from 'Shared/Link';
import connect from 'Shared/connect';
import { translate } from '@avensia/scope';
import { epiPropertyValue } from '@avensia/scope-episerver';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';
import UspBullets from './../ProductDetails/UspBullets';
import { theta, epsilon, pixelsToUnit, zeta, milli, white } from 'Shared/Style';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import Icon from 'Shared/Icon/Cart';
import IconShoppingBag from 'Shared/Icon/ShoppingBag';
import InfoIcon from 'Shared/Icon/Info';
import { formatTitleCase } from 'Shared/string-format';
import AddToCart from 'Product/ProductAddToCart';
import AverageRatings, { Spacing, Size } from 'Product/ProductDetails/AverageRating';
import Price, { Type as PriceType } from 'Pricing/Price';
import { NewBadge, DiscountBadge, DiscountTriBadge } from './Badge';
import { prepareVariationName } from 'Shared/variation-helper';
import { Check } from 'Shared/Icon/Check-Thin';
import Cross from 'Shared/Icon/RemovePlain';
import EsalesProductForListType from '../../../Esales/Models/EsalesProductForList.type';
import AverageRatingsBlack from 'Product/ProductDetails/AverageRatingBlack';
import { PromotionTitle, PromotionItemTitle } from 'Product/ProductCard/ProductCard';

type ConnectStateType = {
  theme: string;
};

type RequiredPropType = {
  product: EsalesProductForListType;
  addToCart: () => Promise<void>;
};

type PropType = StyledProps & RequiredPropType & ConnectStateType;

class ProductCardMobile extends React.Component<PropType> {
  render() {
    const {
      averageRating: productRatings = 0,
      imageUrls,
      price,
      hasMultipleVariants = false,
      hasPromotion = false,
      hasDiscount = false,
      isNew: isProductNew = false,
      variation: { displayName },
      url: productUrl = '',
      bulletPoints = [],
      inStock,
      displayTagDesigner,
      ticket: productTicket,
      displayHighlightImage,
      highlightImageUrl,
      whiteHighlightText,
      hideProductInfo,
      promotions,
    } = this.props.product;
    const images = imageUrls || [];
    const productImage = images.length > 0 ? images[0] : '';
    const productName = formatTitleCase(prepareVariationName(epiPropertyValue(displayName)).trim());
    const { current: currentPrice, original: originalPrice, currency, discountPercentage } = price;
    const isPriceDiscounted = currentPrice < originalPrice;
    const currentTheme = this.props.theme;
    const hasBulletpoints = bulletPoints && bulletPoints.length > 0;
    const IsVxnSite =
      !currentTheme.startsWith('dog') &&
      !currentTheme.startsWith('cli') &&
      !currentTheme.startsWith('lac') &&
      !currentTheme.startsWith('party');
    const ActionButtonIcon =
      hasMultipleVariants || displayTagDesigner ? (
        <InfoIconStyled />
      ) : inStock ? (
        IsVxnSite ? (
          <ShoppingBagIcon />
        ) : (
          <CartIcon />
        )
      ) : (
        <CrossNoStock />
      );

    const imageWithPaddingStyle: Style = {
      paddingTop: pixelsToUnit(10),
    };

    const PromotionNode = (
      <styled.Div css={{ position: 'absolute', right: 0, zIndex: 4 }}>
        <PromotionTitle>{translate('/Product/Badge/Campaign')}</PromotionTitle>
        {promotions.map((promotion, i) => {
          if (i < 4) {
            return (
              <PromotionLink key={i} to={promotion.promotionUrl}>
                <PromotionItemTitle>{promotion.promotionName}</PromotionItemTitle>
              </PromotionLink>
            );
          }
        })}
      </styled.Div>
    );

    return (
      <Base css={this.props.compose()}>
        {hasPromotion && PromotionNode}
        {displayHighlightImage && (
          <HighlightImageWrapper>
            <HighlightImage src={highlightImageUrl} />
          </HighlightImageWrapper>
        )}
        <Body to={productUrl} linkIdentifier={productTicket} css={hideProductInfo && { zIndex: 4 }}>
          <Wrapper
            css={
              displayHighlightImage &&
              (hideProductInfo
                ? { visibility: 'hidden' }
                : whiteHighlightText
                ? { color: 'white', zIndex: 4 }
                : { zIndex: 4 })
            }
          >
            {hasDiscount ? (
              IsVxnSite ? (
                <DiscountTriBadge>{`-${discountPercentage}%`}</DiscountTriBadge>
              ) : (
                <DiscountBadge>{`-${discountPercentage}%`}</DiscountBadge>
              )
            ) : (
              isProductNew && <NewBadge>{`${translate('/Product/Badge/New')}!`}</NewBadge>
            )}
            <ImageStyled
              alt={productName}
              itemProp="image"
              preset={!productImage.includes('.gif') ? ImagePreset.Tiny : undefined}
              ratio={ImageRatio.OneToOne}
              src={productImage}
              imageStyle={imageWithPaddingStyle}
              css={displayHighlightImage && { visibility: 'hidden' }}
              lazy
            />
            <RatingsContainer>
              {!!productRatings ? (
                !currentTheme.startsWith('dog') &&
                !currentTheme.startsWith('cli') &&
                !currentTheme.startsWith('lac') ? (
                  <AverageRatingsBlack average={productRatings} spacing={Spacing.Normal} size={Size.Micro} />
                ) : (
                  <Ratings average={productRatings} spacing={Spacing.Normal} size={Size.Micro} />
                )
              ) : (
                <RatingsNone average={productRatings} spacing={Spacing.Normal} size={Size.Micro} />
              )}
            </RatingsContainer>

            <NameWrapper>
              <Name itemProp="name">{productName}</Name>
            </NameWrapper>
            <MobileBulletWrap>
              <UspBulletsStyled
                bulletLimit
                isMobile
                hasValue={hasBulletpoints}
                bulletPoints={bulletPoints}
                listItemCss={bulletListItemStyle}
              />
            </MobileBulletWrap>
          </Wrapper>
        </Body>
        <ButtonContainer css={displayHighlightImage && { zIndex: 4 }}>
          <ButtonLeftContainer>
            <Prices>
              <CurrentPrice
                current={currentPrice}
                currency={currency}
                priceType={isPriceDiscounted ? PriceType.New : PriceType.Regular}
                css={whiteHighlightText && displayHighlightImage && { color: 'white' }}
              />
              {isPriceDiscounted && (
                <OldPrice
                  current={originalPrice}
                  currency={currency}
                  priceType={PriceType.Old}
                  css={whiteHighlightText && displayHighlightImage && { color: 'white' }}
                />
              )}
            </Prices>
            <StockPlaceholder css={whiteHighlightText && { color: 'white' }}>
              {inStock ? (
                <StockStatus>
                  {translate('/Product/InStock')}
                  <CheckIcon />
                </StockStatus>
              ) : (
                <StockStatus>
                  {translate('/Product/OutOfStock')}
                  <CrossIcon />
                </StockStatus>
              )}
            </StockPlaceholder>
          </ButtonLeftContainer>
          <Action
            css={mobileActionButtonStyle}
            hasVariants={hasMultipleVariants}
            productUrl={productUrl}
            isInStock={inStock}
            addCart={this.props.addToCart}
            displayTagDesigner={displayTagDesigner}
          >
            {ActionButtonIcon}
          </Action>
        </ButtonContainer>
      </Base>
    );
  }
}
export default styled(
  connect(
    (state): ConnectStateType => ({
      theme: state.currentTheme,
    }),
  )(ProductCardMobile),
);

const mobileActionButtonStyle: StyleOrStyleArray = {
  backgroundColor: (theme) => theme.buyButtonProductcardMobile,
  width: '2.5rem',
  height: '2.5rem',
  minWidth: '2.5rem',
  minHeight: '2.5rem',
  borderRadius: '100%',
  marginRight: '0.2rem',
  marginBottom: '0.2rem',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 3,
  lineHeight: '2.1rem',
};

const Base = styled.div({
  boxShadow: (theme) => `0 0.2rem 1rem 0 rgba(${theme.shadowColor}, 0.2)`,
  display: 'flex',
  backgroundColor: (theme) => theme.cardBackgroundColor,
  flexDirection: 'column',
  position: 'relative',
  textAlign: 'center',
});

const HighlightImageWrapper = styled.div({
  color: (theme) => theme.campaignBackgroundColor,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 3,
  height: '100%',
  width: '100%',
});

const HighlightImage = styled.img({
  height: '100%',
  width: 'auto',
  objectFit: 'cover',
});

const Body = styled(Link, {
  color: (theme) => theme.textColor,
});

const Wrapper = styled.div({
  position: 'relative',
});

const ImageStyled = styled(Image, {
  mixBlendMode: 'multiply',
});

const RatingsContainer = styled.div({
  marginTop: pixelsToUnit(5),
});

const Ratings = styled(AverageRatings, {});

const RatingsNone = styled(Ratings, {
  opacity: 0.3,
});

const NameWrapper = styled.div({
  height: '2.4rem',
  padding: {
    x: pixelsToUnit(5),
  },
});

const MobileBulletWrap = styled.div({
  height: '5rem',
});

const Name = styled.div({
  position: 'relative',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: zeta,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  marginTop: pixelsToUnit(3),
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const ButtonContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: pixelsToUnit(5),
});

const ButtonLeftContainer = styled.div({
  maxWidth: '70%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: '2.2rem',
  alignSelf: 'center',
});

const Prices = styled.div({
  display: 'flex',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  justifyContent: 'flex-start',
  padding: {
    x: pixelsToUnit(5),
  },
});

const CurrentPrice = styled(Price, {
  lineHeight: '1rem',
  fontSize: epsilon,
  color: (theme) => theme.textColor,
  marginRight: pixelsToUnit(3),
});

const OldPrice = styled(Price, {
  fontSize: milli,
  paddingTop: '0.15rem',
});

const StockPlaceholder = styled.div({
  lineHeight: '0.9rem',
  height: pixelsToUnit(10),
  padding: {
    x: pixelsToUnit(5),
  },
});

const StockStatus = styled.span({
  fontSize: pixelsToUnit(11),
  letterSpacing: pixelsToUnit(0.3),
  lineHeight: pixelsToUnit(10),
});

const CheckIcon = styled(Check, {
  marginRight: pixelsToUnit(5),
  height: '1rem',
  color: (theme) => theme.buttonTextColor,
});

const InfoIconStyled = styled(InfoIcon, {
  fontSize: '2rem',
  height: 'unset',
  color: (theme) => theme.buttonTextColor,
});

const CartIcon = styled(Icon, {
  width: pixelsToUnit(19.3),
  height: pixelsToUnit(18),
});

const ShoppingBagIcon = styled(IconShoppingBag, {});

const CrossNoStock = styled(Cross, {
  marginLeft: pixelsToUnit(5),
  marginTop: pixelsToUnit(3),
  fontSize: '2rem',
  color: (theme) => theme.buttonTextColor,
});

const CrossIcon = styled(Cross, {
  marginLeft: pixelsToUnit(5),
  height: '1.3rem',
  color: 'red',
});

const bulletListItemStyle: StyleOrStyleArray = {
  fontSize: '0.7rem',
  marginLeft: '0.2rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const UspBulletsStyled = styled(UspBullets, {
  padding: {
    x: pixelsToUnit(5),
    bottom: pixelsToUnit(5),
  },
  margin: {
    xy: 0,
  },
});

const PromotionLink = styled(Link, {
  color: white,
  marginBottom: pixelsToUnit(10),
});

const actionStyle = styled({
  fontSize: theta,
  borderRadius: '0.15rem',
  padding: {
    x: pixelsToUnit(5),
  },
});

const ViewProduct = actionStyle(Button);
const AddCart = actionStyle(AddToCart);

type ActionPropType = {
  hasVariants: boolean;
  productUrl: string;
  isInStock: boolean;
  children: React.ReactNode;
  addCart: () => Promise<void>;
  displayTagDesigner: boolean;
};

const Action = styled((props: ActionPropType & StyledProps) => {
  return props.hasVariants || props.displayTagDesigner ? (
    <ViewProduct
      css={props.compose()}
      to={props.productUrl}
      variant={ButtonVariant.Small}
      appearance={[ButtonAppearance.Primary, ButtonAppearance.Full]}
    >
      {props.children}
    </ViewProduct>
  ) : (
    <AddCart css={props.compose()} disabled={!props.isInStock} variant={ButtonVariant.Small} addToCart={props.addCart}>
      {props.children}
    </AddCart>
  );
});
