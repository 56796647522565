import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { isIE, Breakpoint } from '@avensia/scope';
import { epiPropertyValue } from '@avensia/scope-episerver';
import connect from 'Shared/connect';
import Card from './ProductCard';
import CardMobile from './ProductCardMobile';
import { addToCart } from 'Cart/action-creators';
import ProductCardType from '../../../Esales/Models/EsalesProductForList.type';
import {
  large,
  minMediumMediaQuery,
  minHugeMediaQuery,
  pixelsToUnit,
  minLargeMediaQuery,
  minTinyMediaQuery,
  minSmallMediaQuery,
  minMicroMediaQuery,
} from 'Shared/Style';
import CurerntPageIsCheckout from 'Checkout/Pages/Checkout/current-page-is-checkout';
import { addCartItem } from 'Checkout//action-creators';

export enum Layout {
  Page,
  Block,
}

type RequiredPropType = {
  products: ProductCardType[];
  layout?: Layout;
  margin?: boolean;
  noPlaceholders?: boolean;
};

type ConnectStateType = {
  isCheckoutPage: boolean;
  currentBreakpoint: number;
};

type ConnectActionType = {
  addToCart: (code: string, quantity: number, ticket: string) => Promise<void>;
  addCartItem: (code: string, quantity: number, ticket: string) => any;
};

type PropType = RequiredPropType & ConnectStateType & ConnectActionType;

const MOBILE_CARD_WIDTH = 145;
const Base = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-around',
  [minTinyMediaQuery]: {
    justifyContent: 'space-between',
  },
});

const PAGE_MICRO_CARDS_PER_ROW = 2;
const PAGE_TINY_CARDS_PER_ROW = 3;
const PAGE_SMALL_CARDS_PER_ROW = 4;
const PAGE_MEDIUM_CARDS_PER_ROW = 5;
const PAGE_LARGE_CARDS_PER_ROW = 6;
const PAGE_TINY_GUTTER = 4;
const PAGE_SMALL_GUTTER = 6;
const PAGE_MEDIUM_GUTTER = 20;
const PAGE_LARGE_GUTTER = 12;
const pageStyled = styled({
  marginRight: 0,
  marginBottom: large,
  flexBasis: '100%',
  [minMicroMediaQuery]: {
    flexBasis: `calc(100% / ${PAGE_MICRO_CARDS_PER_ROW} - ${PAGE_SMALL_GUTTER}px)`,
  },
  [minTinyMediaQuery]: {
    flexBasis: `calc(100% / ${PAGE_TINY_CARDS_PER_ROW} - ${PAGE_TINY_GUTTER}px)`,
  },
  [minSmallMediaQuery]: {
    flexBasis: `calc(100% / ${PAGE_SMALL_CARDS_PER_ROW} - ${PAGE_SMALL_GUTTER}px)`,
  },
  [minMediumMediaQuery]: {
    flexBasis: `calc(100% / ${PAGE_MEDIUM_CARDS_PER_ROW} - ${PAGE_MEDIUM_GUTTER}px)`,
  },
  [minLargeMediaQuery]: {
    flexBasis: `calc(100% / ${PAGE_LARGE_CARDS_PER_ROW} - ${PAGE_LARGE_GUTTER}px)`,
  },
  [minHugeMediaQuery]: {
    flexBasis: `calc(100% / ${PAGE_LARGE_CARDS_PER_ROW} - ${PAGE_LARGE_GUTTER}px)`,
  },
});

const BLOCK_MICRO_CARDS_PER_ROW = 2;
const BLOCK_TINY_CARDS_PER_ROW = 3;
const BLOCK_SMALL_CARDS_PER_ROW = 4;
const BLOCK_MEDIUM_CARDS_PER_ROW = 5;
const BLOCK_LARGE_CARDS_PER_ROW = 6;
const BLOCK_TINY_GUTTER = 3;
const BLOCK_SMALL_GUTTER = 6;
const BLOCK_MEDIUM_GUTTER = 15;
const BLOCK_LARGE_GUTTER = 20;

const blockStyled = styled({
  marginRight: 0,
  flexBasis: '100%',
  [minMicroMediaQuery]: {
    flexBasis: `calc(100% / ${BLOCK_MICRO_CARDS_PER_ROW} - ${BLOCK_SMALL_GUTTER}px)`,
  },
  [minTinyMediaQuery]: {
    flexBasis: `calc(100% / ${BLOCK_TINY_CARDS_PER_ROW} - ${BLOCK_TINY_GUTTER}px)`,
  },
  [minSmallMediaQuery]: {
    flexBasis: `calc(100% / ${BLOCK_SMALL_CARDS_PER_ROW} - ${BLOCK_SMALL_GUTTER}px)`,
  },
  [minMediumMediaQuery]: {
    flexBasis: `calc(100% / ${BLOCK_MEDIUM_CARDS_PER_ROW} - ${BLOCK_MEDIUM_GUTTER}px)`,
    flexGrow: 1,
    margin: {
      right: pixelsToUnit(BLOCK_MEDIUM_GUTTER),
    },
  },
  [minLargeMediaQuery]: {
    flexBasis: `calc(100% / ${BLOCK_LARGE_CARDS_PER_ROW} - ${BLOCK_LARGE_GUTTER}px)`,
    marginRight: pixelsToUnit(5),
    marginLeft: pixelsToUnit(5),
  },
});

const baseStyled = styled({
  flexBasis: pixelsToUnit(MOBILE_CARD_WIDTH),
  flexGrow: 0,
  flexShrink: 0,
  minWidth: 0,
});

const BaseCard = baseStyled(Card);
const BaseCardMobile = baseStyled(CardMobile);
const PageCard = pageStyled(BaseCard);
const PageCardMobile = pageStyled(BaseCardMobile);
const BlockCard = blockStyled(BaseCard);
const BlockCardMobile = blockStyled(BaseCardMobile);

const BasePlaceholder = baseStyled(styled.Div, {
  margin: {
    xy: 0,
  },
  padding: {
    xy: 0,
  },
});
const PagePlaceholder = pageStyled(BasePlaceholder);
const BlockPlaceholder = blockStyled(BasePlaceholder);

class List extends React.PureComponent<PropType & StyledProps> {
  addToCart = async (code: string, ticket: string) => {
    try {
      const result = this.props.isCheckoutPage
        ? this.props.addCartItem(code, 1, ticket)
        : await this.props.addToCart(code, 1, ticket);
      return result;
    } catch (e) {
      console.error('Add item to cart error', e);
      return Promise.reject(null);
    }
  };

  render() {
    const isMobile = this.props.currentBreakpoint < Breakpoint.Medium;
    const ProductCard = this.props.layout === Layout.Block ? BlockCard : PageCard;
    const ProductCardMobile = this.props.layout === Layout.Block ? BlockCardMobile : PageCardMobile;
    const Placeholder = this.props.layout === Layout.Block ? BlockPlaceholder : PagePlaceholder;
    const ieBlockCardStyle = {
      [minMediumMediaQuery]: {
        flexBasis: 'auto',
        width: `calc(100% / ${BLOCK_LARGE_CARDS_PER_ROW})`,
      },
    };
    const desktopCardStyle = {
      marginBottom: pixelsToUnit(30),
      marginRight: this.props.margin ? 5 : 0,
    };

    const mobileCardStyle = {
      margin: { x: 0, y: pixelsToUnit(5), right: this.props.margin ? 5 : 0 },
    };

    return (
      <Base css={this.props.compose()}>
        {this.props.products.map((product, i) => {
          const code = epiPropertyValue(product.variation.code);
          return (
            <React.Fragment key={code}>
              {isMobile ? (
                <ProductCardMobile
                  key={i}
                  css={isIE() && this.props.layout === Layout.Block ? ieBlockCardStyle : mobileCardStyle}
                  addToCart={() => this.addToCart(code, product.ticket)}
                  product={product}
                />
              ) : (
                <ProductCard
                  key={i}
                  css={isIE() && this.props.layout === Layout.Block ? ieBlockCardStyle : desktopCardStyle}
                  addToCart={() => this.addToCart(code, product.ticket)}
                  product={product}
                />
              )}
            </React.Fragment>
          );
        })}
        {!this.props.noPlaceholders &&
          new Array(5)
            .fill(0)
            .map((elem, index) => (
              <Placeholder key={index} css={isIE() && this.props.layout === Layout.Block && ieBlockCardStyle} />
            ))}
      </Base>
    );
  }
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      isCheckoutPage: CurerntPageIsCheckout(state.currentPage),
      currentBreakpoint: state.currentBreakpoint,
    }),
    (dispatch): ConnectActionType => ({
      addToCart(code: string, quantity: number, ticket: string) {
        return dispatch(addToCart(code, quantity, ticket, 'Produktlista'));
      },
      addCartItem(code: string, quantity: number, ticket: string) {
        return dispatch(addCartItem(code, quantity, ticket, 'Kassakampanjer'));
      },
    }),
  )(List),
);
