import React from 'react';
import { translate } from '@avensia/scope';
import FacetPanel, { Navigation, LocationType } from 'Shared/Facet/Panel';
import FacetType from '../../../Esales/Models/Facet.type';
import FacetValueType from '../../../Esales/Models/FacetValue.type';
import Panel, { CloseType } from 'Shared/Panel';

type PropType = {
  facets: FacetType[];
  toggleFacetValue: (fieldName: string, facetValue: FacetValueType, selected?: boolean) => Promise<boolean>;
  resetFacetValues: () => Promise<boolean>;
  isOpen: boolean;
  closePanel: () => void;
};
type StateType = {
  location: LocationType;
};

export default class extends React.Component<PropType, StateType> {
  state: StateType = {
    location: {
      type: Navigation.Menu,
      facet: null,
      label: null,
    },
  };
  toMenu = () => this.setLocation({ ...this.state.location, type: Navigation.Menu });
  setLocation = (location: LocationType) => {
    if (location !== this.state.location) {
      this.setState({ location });
    }
  };
  closeHandler = () => {
    if (this.state.location.type === Navigation.Menu) {
      this.props.closePanel();
    } else {
      this.toMenu();
    }
  };
  render() {
    const panelTitle =
      this.state.location.type === Navigation.Menu ? translate('/Shared/Filter') : this.state.location.label;
    return (
      <Panel
        title={panelTitle}
        isOpen={this.props.isOpen}
        onClose={this.closeHandler}
        closeType={this.state.location.type === Navigation.Facet && CloseType.Back}
      >
        <FacetPanel
          facets={this.props.facets}
          toggleFacetValue={this.props.toggleFacetValue}
          resetFacetValues={this.props.resetFacetValues}
          setLocation={this.setLocation}
          location={this.state.location}
          close={this.props.closePanel}
        />
      </Panel>
    );
  }
}
