import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import { Actionbar } from 'SiteLayout/Tray';
import { Down as ArrowDown } from 'Shared/Icon/Arrow';
import FilterIcon from 'Shared/Icon/Filter';
import { white, zeta, theta, pixelsToUnit } from 'Shared/Style';
import SelectOptionType from 'Shared/Fields/SelectOption.type';
import CompactSort from './CompactSort';
import CompactFilter from './CompactFilter';
import Button, { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';
import FacetType from '../../../Esales/Models/Facet.type';
import FacetValueType from '../../../Esales/Models/FacetValue.type';

type FilterPropType = {
  facets: FacetType[];
  toggleFacetValue: (fieldName: string, facetValue: FacetValueType, selected?: boolean) => Promise<boolean>;
  resetFacetValues: () => Promise<boolean>;
};

type PropType = FilterPropType & {
  sortValue: string;
  sortOptions: SelectOptionType[];
  onSortChange: (sortBy: string) => Promise<boolean>;
};

type StateType = {
  filterOpen: boolean;
  sortingOpen: boolean;
};

const FCButtonWrapper = styled.div({
  backgroundColor: white,
  display: 'flex',
  margin: {
    top: 0,
    bottom: pixelsToUnit(10),
    x: 'auto',
  },
  boxShadow: theme => `0 5px 15px 0 rgba(${theme.shadowColor}, 0.25)`,
  padding: {
    xy: pixelsToUnit(5),
  },
  width: '90vw',
});

const SortIcon = styled(ArrowDown, {
  color: theme => theme.textColor,
  fontSize: zeta,
  marginLeft: pixelsToUnit(15),
  verticalAlign: 'middle',
});

const FCButtonText = styled.span({
  font: {
    size: theta,
    weight: 'normal',
  },
});

export default class CompactFilterSort extends React.Component<PropType, StateType> {
  state: StateType = {
    sortingOpen: false,
    filterOpen: false,
  };
  toggleSortingPanel = () => {
    this.setState({ sortingOpen: !this.state.sortingOpen });
  };
  toggleFilterPanel = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };
  render() {
    return (
      <div>
        <Actionbar>
          <FCButtonWrapper>
            <Button
              variant={ButtonVariant.Medium}
              appearance={[ButtonAppearance.Bare, ButtonAppearance.Half]}
              onClick={this.toggleFilterPanel}
            >
              <FilterIcon css={{ marginRight: pixelsToUnit(15) }} />
              <FCButtonText>{translate('/Shared/Filter')}</FCButtonText>
            </Button>
            <Button
              variant={ButtonVariant.Medium}
              appearance={[ButtonAppearance.Bare, ButtonAppearance.Half]}
              onClick={this.toggleSortingPanel}
            >
              <FCButtonText>{translate('/Shared/Sort')}</FCButtonText>
              <SortIcon />
            </Button>
          </FCButtonWrapper>
        </Actionbar>
        <CompactSort
          sortOptions={this.props.sortOptions}
          onSortChange={this.props.onSortChange}
          isOpen={this.state.sortingOpen}
          closePanel={this.toggleSortingPanel}
        />
        <CompactFilter
          facets={this.props.facets}
          toggleFacetValue={this.props.toggleFacetValue}
          resetFacetValues={this.props.resetFacetValues}
          isOpen={this.state.filterOpen}
          closePanel={this.toggleFilterPanel}
        />
      </div>
    );
  }
}
