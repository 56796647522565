import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { theta, medium, large, micro, pixelsToUnit } from 'Shared/Style';
import { ValuePropType, ViewportPropType } from './shared';
import { StyleOrStyleArray } from '@glitz/type';

type PropType = {
  bulletPoints: string[];
  bulletLimit?: boolean;
  isMobile?: boolean;
  listItemCss?: StyleOrStyleArray;
} & ViewportPropType &
  ValuePropType;

const ListStyleType = styled.div({
  display: 'inline-block',
  width: pixelsToUnit(8),
  height: pixelsToUnit(8),
  backgroundColor: (Theme) => Theme.bulletpoints,
  borderRadius: '50%',
  marginRight: medium,
  verticalAlign: 'middle',
});

const ListStyleMobileType = styled(ListStyleType, {
  marginRight: micro,
  width: pixelsToUnit(6),
  height: pixelsToUnit(6),
});

const Li = styled.li({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Value = styled.span({
  textAlign: 'left',
  verticalAlign: 'middle',
});

const UspBullets = styled(
  (props: PropType & StyledProps) => {
    let Sliced: string[] = props.bulletPoints;
    if (props.bulletLimit) {
      Sliced = props.bulletPoints.slice(0, 4);
    }
    const UlComponent = props.isCompact ? MobileUl : DesktopUl;
    return props.hasValue ? (
      <UlComponent css={props.compose()}>
        {Sliced.map((bulletPoint, index) => (
          <Li title={bulletPoint} key={bulletPoint + index} css={props.listItemCss}>
            {props.isMobile ? <ListStyleMobileType /> : <ListStyleType />}
            <Value>{bulletPoint}</Value>
          </Li>
        ))}
      </UlComponent>
    ) : null;
  },
  {
    listStyleType: 'none',
    textAlign: 'left',
  },
);

export default UspBullets;

const MobileUl = styled.ul({
  minWidth: '138px',
  maxWidth: '308px',
});

const DesktopUl = styled.ul({
  fontSize: theta,
  margin: {
    y: large,
    x: 0,
  },
  minWidth: 'initial',
  maxWidth: 'initial',
});
