/**
 * @ComponentFor SearchPageViewModel
 */
import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import { EpiProperty, epiPropertyValue } from '@avensia/scope-episerver';
import { searchPageUrl } from 'Shared/known-urls';
import { PageType } from 'Shared/State';
import { Main, Section, Appearance as PageAppearance } from 'Shared/PageLayout';
import ProductListing from 'Product/ProductListing';
import Ul from 'Shared/Generic/Ul';
import Button, { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';
import SearchPageViewModelType from './SearchPageViewModel.type';
import { pixelsToUnit, moderate, medium, delta, minMediumMediaQuery, alpha } from 'Shared/Style';
import { SEARCH_QUERY_NAME } from 'Search/action-creators';
import Products from 'Product/ProductCard/List';
import H1 from 'Shared/Generic/h1';

const Suggestion = styled.li({
  marginRight: medium,
  marginBottom: medium,
  display: 'flex',
});

const RecommendationText = styled.span({
  marginRight: moderate,
});

const SuggestionsList = styled(Ul, {
  flexWrap: 'wrap',
  display: 'inline-flex',
  verticalAlign: 'middle',
  width: '100%',
});

const PageTitle = styled.h1({
  display: 'block',
  fontSize: delta,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.6),
  lineHeight: 'normal',
  textAlign: 'center',
  textTransform: 'lowercase',
  ':first-letter': {
    textTransform: 'uppercase',
  },
  [minMediumMediaQuery]: {
    display: 'inline-block',
    fontSize: alpha,
    fontWeight: 'normal',
    textAlign: 'left',
  },
});

const Header = styled(H1, {
  fontSize: delta,
  lineHeight: 'normal',
  marginBottom: pixelsToUnit(15),
  [minMediumMediaQuery]: {
    fontSize: alpha,
    marginBottom: pixelsToUnit(30),
  },
});

const SuggestionButton = styled(Button, {
  padding: {
    x: 15,
    y: 0,
  },
  maxWidth: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [minMediumMediaQuery]: {
    maxWidth: 'none',
  },
});

type PropType = SearchPageViewModelType & PageType;

export default class SearchPage extends React.Component<PropType> {
  render() {
    const searchSuggestions = this.props.searchSuggestions || [];
    const showingResultsFromDidYouMean =
      this.props.originalSearchPhrase && this.props.searchPhrase !== this.props.originalSearchPhrase;

    const resultLabel = showingResultsFromDidYouMean
      ? epiPropertyValue(this.props.page.showingResultsForInstead)
        ? epiPropertyValue(this.props.page.showingResultsForInstead, {
          originalSearchPhrase: this.props.originalSearchPhrase,
          usedSearchPhrase: this.props.searchPhrase,
        })
        : ''
      : epiPropertyValue(this.props.page.showingResultsFor)
        ? epiPropertyValue(this.props.page.showingResultsFor).replace(/{originalSearchPhrase}/, this.props.searchPhrase)
        : '';
    return (
      <Main appearance={PageAppearance.Narrow}>
        {searchSuggestions.length > 0 && (
          <Section>
            {showingResultsFromDidYouMean && searchSuggestions.length !== 0 && (
              <>
                <EpiProperty for={this.props.page.didYouMean} component={RecommendationText} />
                <SuggestionsList row>
                  {searchSuggestions.map((suggestion, i) => (
                    <Suggestion key={suggestion.text}>
                      <SuggestionButton
                        to={searchPageUrl() + `?${SEARCH_QUERY_NAME}=` + suggestion.text}
                        variant={ButtonVariant.Small}
                        appearance={ButtonAppearance.Secondary}
                      >
                        {suggestion.text}
                      </SuggestionButton>
                    </Suggestion>
                  ))}
                </SuggestionsList>
              </>
            )}
          </Section>
        )}
        {this.props.products.totalProductCount === 0 ? (
          <div>
            <EpiProperty for={this.props.page.noHits} component="h1" />
            {!!this.props.products.products.length && (
              <Section>
                <Header>{translate('/Checkout/Header/RecommendedBestSellers')}</Header>
                <Products products={this.props.products.products} />
              </Section>
            )}
          </div>
        ) : (
            <ProductListing
              pageTitle={!!resultLabel && <PageTitle>{resultLabel}</PageTitle>}
              {...this.props}
              isSearchPage
            />
          )}
      </Main>
    );
  }
}
