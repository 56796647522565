import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import SelectOptionType from 'Shared/Fields/SelectOption.type';
import Ul from 'Shared/Generic/Ul';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import { thin, alto, truncate, pixelsToUnit } from 'Shared/Style';
import Panel from 'Shared/Panel';

type PropType = {
  sortOptions: SelectOptionType[];
  onSortChange: (sortBy: string) => Promise<boolean>;
  isOpen: boolean;
  closePanel: () => void;
};

const OptionLi = styled.li({
  borderBottom: {
    width: thin,
    style: 'solid',
    color: alto,
  },
});

const Option = styled(Button, {
  minWidth: '100%',
  maxWidth: '100%',
  ...truncate(),
});

const OptionContent = styled.div({
  padding: {
    y: 0,
    x: pixelsToUnit(20),
  },
  textAlign: 'left',
  width: '100%',
});

export default class extends React.Component<PropType> {
  triggerSortChange = (val: string) => {
    return this.props.onSortChange(val).then(val => {
      this.props.closePanel();
      return val;
    });
  };

  render() {
    const { sortOptions } = this.props;
    return (
      <Panel title={translate('/Shared/Sort')} isOpen={this.props.isOpen} onClose={this.props.closePanel}>
        <Ul bare>
          {sortOptions.map(option => (
            <OptionLi key={option.value}>
              <Option
                variant={ButtonVariant.Medium}
                appearance={ButtonAppearance.Bare}
                onClick={() => this.triggerSortChange(option.value)}
              >
                <OptionContent>{option.text}</OptionContent>
              </Option>
            </OptionLi>
          ))}
        </Ul>
      </Panel>
    );
  }
}
