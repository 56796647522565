import React from 'react';
import { styled } from '@glitz/react';
import { Checkbox } from '../Fields/Toggle';
import FacetValueType from '../../../Esales/Models/FacetValue.type';
import { sigma, medium, boulder, pixelsToUnit, truncate } from '../Style';

type PropType = {
  facetValue: FacetValueType;
  onFacetValueChange: (facetValue: FacetValueType, selected: boolean) => void;
};

export default function FacetValue(props: PropType) {
  function selectFacetValue(event: React.ChangeEvent<HTMLInputElement>) {
    props.onFacetValueChange(props.facetValue, event.target.checked);
  }

  return (
    <Facet
      css={{
        color: (theme) => theme.textColor,
      }}
      title={props.facetValue.text}
    >
      <Filter checked={props.facetValue.selected} onChange={selectFacetValue}>
        <Text>{props.facetValue.text}</Text>
        <Count>({props.facetValue.count})</Count>
      </Filter>
    </Facet>
  );
}

const Facet = styled.li({
  fontSize: sigma,
  marginBottom: medium,
});

const Filter = styled(Checkbox, {
  cursor: 'pointer',
  maxWidth: '100%',
  width: '100%',
});

const Text = styled.span({
  paddingLeft: pixelsToUnit(12),
  width: '80%',
  ...truncate(),
});

const Count = styled.span({
  paddingLeft: pixelsToUnit(5),
  color: boulder,
});
