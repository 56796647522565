import React from 'react';
import { styled } from '@glitz/react';
import { pseudo } from '@glitz/core';
import { URLX } from '@avensia/scope';
import Link from 'Shared/Link';
import connect from 'Shared/connect';
import { pixelsToUnit, thick, delison, theta } from 'Shared/Style';
import NavigationItem from 'Shared/NavigationItem/NavigationItem.type';
import Ul from 'Shared/Generic/Ul';

type ConnectStateType = {
  currentPageUrl: string;
};

type PropType = {
  items: NavigationItem[];
} & ConnectStateType;

const ListItem = styled.li({
  borderBottom: {
    width: thick,
    style: 'solid',
    color: (theme) => theme.borderColor,
  },
  ...pseudo(':last-child', {
    border: {
      xy: {
        style: 'none',
      },
    },
  }),
});

const LinkCurrent = styled(Link, {
  display: 'block',
  fontWeight: 'bold',
  fontSize: delison,
  textTransform: 'uppercase',
  padding: {
    y: pixelsToUnit(14),
  },
  color: 'inherit',
});

const LinkDefault = styled(Link, {
  display: 'block',
  fontSize: delison,
  textTransform: 'uppercase',
  paddingTop: pixelsToUnit(14),
  paddingBottom: pixelsToUnit(14),
  color: 'inherit',
});

const SubList = styled(Ul, {
  listStyle: 'none',
  marginTop: pixelsToUnit(-10),
  marginLeft: pixelsToUnit(11),
  marginBottom: pixelsToUnit(11),
});

const SubLinkDefault = styled(Link, {
  display: 'block',
  fontSize: theta,
  color: 'inherit',
  lineHeight: pixelsToUnit(28),
});

const SubLinkCurrent = styled(Link, {
  display: 'block',
  fontSize: theta,
  fontWeight: 'bold',
  lineHeight: pixelsToUnit(28),
  color: (theme) => theme.filterLink,
});

const SubMenu = (props: PropType) => {
  const currentUrl = new URLX(props.currentPageUrl).pathname;
  const urlPart = currentUrl.split('/')[2];
  return (
    <Ul
      css={{
        listStyle: 'none',
        border: {
          xy: {
            width: thick,
          },
          y: {
            style: 'solid',
            color: (theme) => theme.borderColor,
          },
        },
        marginBottom: pixelsToUnit(30),
      }}
    >
      {props.items.map((item) => {
        const LinkItem = item.url === currentUrl || urlPart === item.url.split('/')[2] ? LinkCurrent : LinkDefault;
        return (
          <ListItem key={item.url}>
            <LinkItem to={item.url}>{item.name}</LinkItem>
            {item.children && item.children.length > 0 && (
              <SubList>
                {item.children.map((child) => {
                  const SubLinkItem = child.url === currentUrl ? SubLinkCurrent : SubLinkDefault;
                  return (
                    <li key={child.url}>
                      <SubLinkItem to={child.url}>
                        {child.name}
                        {child.children &&
                          child.children.length > 0 &&
                          child.children.map((child) => {
                            return (
                              <li style={{ marginLeft: pixelsToUnit(5) }}>
                                <SubLinkItem to={child.url}> {child.name}</SubLinkItem>
                              </li>
                            );
                          })}
                      </SubLinkItem>
                    </li>
                  );
                })}
              </SubList>
            )}
          </ListItem>
        );
      })}
    </Ul>
  );
};

export default connect(
  (state): ConnectStateType => ({
    currentPageUrl: state.currentPage.url,
  }),
)(SubMenu);
