import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import FacetValue from './FacetValue';
import FacetType from '../../../Esales/Models/Facet.type';
import FacetValueType from '../../../Esales/Models/FacetValue.type';
import Ul from '../Generic/Ul';
import Accordion from 'Shared/Accordion';
import { Up, Down } from 'Shared/Icon/Arrow';
import { pixelsToUnit, epsilon, sigma, thin } from '../Style';

export const VISIBLE_FILTER_THRESHOLD = 5;

type PropType = FacetType & {
  onFacetValueChange: (fieldName: string, facetValue: FacetValueType, selected: boolean) => void;
};

type StateType = {
  someValuesAreHidden?: boolean;
  isOpen: boolean;
};

const Base = styled.li({
  borderRadius: thin,
});

const FilterAccordion = styled(Accordion, {
  minHeight: pixelsToUnit(22),
});

const Title = styled.span({
  float: 'left',
  fontSize: epsilon,
});

const DownIcon = styled(Down, {
  float: 'right',
  height: '100%',
  width: pixelsToUnit(13),
});

const UpIcon = styled(Up, {
  float: 'right',
  height: '100%',
  width: pixelsToUnit(13),
});

const ViewAll = styled.div({
  borderBottom: {
    width: thin,
    style: 'solid',
    color: '#5e3975',
  },
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: sigma,
});

export default class Facet extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      someValuesAreHidden: props.facetValues.length > VISIBLE_FILTER_THRESHOLD,
      isOpen: true,
    };
  }

  toggleShowAll = () => this.setState({ someValuesAreHidden: !this.state.someValuesAreHidden });
  toggleAccordion = () => this.setState({ isOpen: !this.state.isOpen });
  handleFacetValueChange = (facetValue: FacetValueType, isChecked: boolean) => {
    this.props.onFacetValueChange(this.props.fieldName, facetValue, isChecked);
  };

  renderHeader(title: string) {
    return (
      <styled.Div
        css={{
          height: pixelsToUnit(22),
          marginBottom: this.state.isOpen ? pixelsToUnit(20) : 0,
        }}
      >
        <Title>{title}</Title>
        {this.state.isOpen ? <UpIcon /> : <DownIcon />}
      </styled.Div>
    );
  }

  renderBody() {
    const exceedVisibleCount = this.props.facetValues.length > VISIBLE_FILTER_THRESHOLD;
    let filters = [...this.props.facetValues];

    if (this.state.someValuesAreHidden) {
      filters = filters.slice(0, VISIBLE_FILTER_THRESHOLD);
    }
    return (
      <styled.Div css={{ marginBottom: this.state.isOpen ? pixelsToUnit(36) : 0 }}>
        <Ul column>
          {filters.map(facetValue => (
            <FacetValue facetValue={facetValue} key={facetValue.id} onFacetValueChange={this.handleFacetValueChange} />
          ))}
        </Ul>
        {exceedVisibleCount && (
          <ViewAll onClick={this.toggleShowAll}>
            {this.state.someValuesAreHidden ? translate('/Shared/ShowAll') : translate('/Shared/ShowLess')}
          </ViewAll>
        )}
      </styled.Div>
    );
  }

  render() {
    return (
      <Base>
        <FilterAccordion
          body={this.renderBody()}
          header={this.renderHeader(this.props.displayName)}
          isOpen={this.state.isOpen}
          onToggle={this.toggleAccordion}
        />
      </Base>
    );
  }
}
