import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import {
  connectWithFeedback,
  Behavior as ButtonBehavior,
  Appearance as ButtonAppearance,
  ConnectPropType as FeedbackPropType,
} from 'Shared/Button/Feedback';
import { Variant } from 'Shared/Button';

type PropType = {
  addToCart: () => Promise<void>;
  disabled?: boolean;
  variant?: Variant;
  children?: React.ReactNode;
} & StyledProps &
  FeedbackPropType;

export default styled(
  connectWithFeedback({ behavior: ButtonBehavior.KeepEnabled })(
    class AddToCart extends React.Component<PropType> {
      onClick = (event: React.MouseEvent) => {
        this.props.feedback.push(this.props.addToCart());
        event.stopPropagation();
        event.preventDefault();
      };
      render() {
        const FeedbackButton = this.props.feedback.Button;
        return (
          <FeedbackButton
            css={this.props.compose()}
            variant={this.props.variant}
            appearance={[ButtonAppearance.Primary, ButtonAppearance.Full]}
            disabled={this.props.disabled}
            onClick={this.onClick}
          >
            {this.props.children}
          </FeedbackButton>
        );
      }
    },
  ),
);
