import React from 'react';
import { translate, scrollPosition, isIOS } from '@avensia/scope';
import { Item, ItemValue } from './Item';
import Slides, { Slide } from 'Shared/Slides';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import FacetType from '../../../../Esales/Models/Facet.type';
import FacetValueType from '../../../../Esales/Models/FacetValue.type';
import { pixelsToUnit, depth, theta, lightGrey, small } from 'Shared/Style';
import { styled } from '@glitz/react';

const Base = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
});

const Content = styled.div({
  height: '100%',
  ':after': {
    content: '""',
    display: 'block',
    height: pixelsToUnit(75),
  },
});

const FacetButtonsPanel = styled.div({
  bottom: 0,
  backgroundColor: 'white',
  fontSize: theta,
  padding: {
    y: pixelsToUnit(10),
    x: 0,
  },
  position: 'fixed',
  width: '100%',
});
const FacetButtonWrapper = styled.div({
  display: 'flex',
  width: '90%',
  margin: {
    y: 0,
    x: 'auto',
  },
  padding: {
    xy: pixelsToUnit(5),
  },
  ...depth(),
});

const ClearFilterText = styled.span({
  color: lightGrey,
});

const Count = styled.span({
  marginLeft: small,
  color: lightGrey,
});

export enum Navigation {
  Menu,
  Facet,
}

export type LocationType = {
  type: Navigation;
  facet: string;
  label: string;
};

type PropType = {
  facets: FacetType[];
  toggleFacetValue: (fieldName: string, facetValue: FacetValueType, selected?: boolean) => Promise<boolean>;
  resetFacetValues: () => Promise<boolean>;
  setLocation: (current: LocationType) => void;
  close: () => void;
  location: LocationType;
};

export default class FacetPanel extends React.Component<PropType> {
  facetValuesContent: HTMLDivElement;

  selectedValues(facet: FacetType) {
    if (Array.isArray(facet.facetValues)) {
      return facet.facetValues.filter((value) => value.selected);
    } else {
      return [];
    }
  }
  getTotalFilters() {
    let total = 0;
    if (this.props.facets.length > 0) {
      total = this.props.facets
        .map((facet) => facet.facetValues)
        .map((facetValuesArrElem) => facetValuesArrElem.filter((facetValue) => facetValue.selected).length)
        .reduce((accum, currVal) => accum + currVal);
    }
    return total;
  }
  navigateFacet = (name: string, label: string) => {
    this.props.setLocation({
      facet: name,
      type: Navigation.Facet,
      label,
    });
  };
  async resetScrollOnSuccess(loading: Promise<boolean>) {
    const success = await loading;
    if (success) {
      scrollPosition(0);
    }
  }
  toggleFacetValue = async (fieldName: string, facetValue: FacetValueType, selected?: boolean) => {
    this.resetScrollOnSuccess(this.props.toggleFacetValue(fieldName, facetValue, selected));
  };
  resetFacetValues = async () => {
    this.resetScrollOnSuccess(this.props.resetFacetValues());
  };

  render() {
    const currentFacet =
      this.props.location.facet && this.props.facets.find((facet) => facet.fieldName === this.props.location.facet);
    if (currentFacet && this.facetValuesContent) {
      this.facetValuesContent.scrollTo(0, 0);
    }

    return (
      <Base>
        <Slides css={{ flexGrow: 1 }}>
          <Slide isCurrent={this.props.location.type === Navigation.Menu}>
            <Content
              css={
                !isIOS() && {
                  overflowY: 'auto',
                }
              }
            >
              {this.props.facets.map((facet) => (
                <Item
                  key={facet.fieldName}
                  onClick={() => this.navigateFacet(facet.fieldName, facet.displayName)}
                  selected={
                    this.selectedValues(facet)
                      .map((value) => value.text)
                      .join(', ') || translate('/ProductListing/AllFilter')
                  }
                  label={facet.displayName}
                />
              ))}
            </Content>
          </Slide>
          <Slide isCurrent={this.props.location.type === Navigation.Facet}>
            <Content
              css={
                !isIOS() && {
                  overflowY: 'auto',
                }
              }
              ref={(content: HTMLDivElement) => (this.facetValuesContent = content)}
            >
              {currentFacet &&
                currentFacet.facetValues.map((value) => (
                  <ItemValue
                    key={value.id}
                    selected={value.selected}
                    onClick={() => this.toggleFacetValue(currentFacet.fieldName, value)}
                    disabled={!value.selected && value.count === 0}
                  >
                    {value.text}
                    <Count>({value.count})</Count>
                  </ItemValue>
                ))}
            </Content>
          </Slide>
        </Slides>
        <FacetButtonsPanel>
          <FacetButtonWrapper>
            <Button
              variant={ButtonVariant.Medium}
              onClick={this.resetFacetValues}
              appearance={[ButtonAppearance.Bare, ButtonAppearance.Half]}
            >
              <ClearFilterText>{`${translate('/Shared/ClearFilter')}  (${this.getTotalFilters()})`}</ClearFilterText>
            </Button>
            <Button
              variant={ButtonVariant.Medium}
              onClick={this.props.close}
              appearance={[ButtonAppearance.Bare, ButtonAppearance.Half]}
            >
              {translate('/Shared/UseFilter')}
            </Button>
          </FacetButtonWrapper>
        </FacetButtonsPanel>
      </Base>
    );
  }
}
