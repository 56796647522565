import React from 'react';
import { styled, applyClassName } from '@glitz/react';
import { Unchecked, Checked } from '../Icon/Checkbox';
import { Unchecked as UncheckedRadio, Checked as CheckedRadio } from '../Icon/Radio';
import * as style from '../Style';

const sharedCheckBoxStyle = {
  width: style.pixelsToUnit(15),
  height: style.pixelsToUnit(15),
};

const StyledChecked = styled(Checked, sharedCheckBoxStyle);
const StyledUnchecked = styled(Unchecked, sharedCheckBoxStyle);

const CheckedCheckBox = styled(StyledChecked, {
  color: (theme) => theme.secondaryColor,
});

type PropType = React.InputHTMLAttributes<HTMLInputElement> & {
  elementRef?: (el: HTMLInputElement) => void;
};

type StateType = {
  checked: boolean;
};

class Toggle extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      checked: typeof props.checked === 'undefined' ? false : props.checked,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps: PropType) {
    const checked = typeof nextProps.checked === 'undefined' ? this.state.checked : nextProps.checked;
    if (this.props.checked !== checked) {
      this.setState({ checked });
    }
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    if (typeof this.props.checked === 'undefined') {
      const checked = (e.target as HTMLInputElement).checked;
      if (checked !== this.state.checked) {
        this.setState({ checked });
      }
    }
  };
  render() {
    const restProps = Object.assign({}, this.props) as PropType;
    delete restProps.children;
    delete restProps.className;
    delete restProps.style;

    return (
      <label className={this.props.className} style={this.props.style}>
        <Input {...restProps} onChange={this.onChange} ref={this.props.elementRef} />
        {this.props.type === 'checkbox' ? (
          this.state.checked ? (
            <CheckedCheckBox />
          ) : (
            <StyledUnchecked />
          )
        ) : this.state.checked ? (
          <CheckedRadio />
        ) : (
          <UncheckedRadio />
        )}
        {this.props.children}
      </label>
    );
  }
}

function factory(type: 'radio' | 'checkbox') {
  return styled(
    applyClassName((props: PropType) => <Toggle {...props} type={type} />),
    {
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
    },
  );
}

export const Radio = factory('radio');
export const Checkbox = factory('checkbox');
const Input = styled.input(style.visuallyHidden());
