import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { pixelsToUnit } from 'Shared/Style';
import { StarBlack, StarOutlineBlack, HalfStarBlack } from 'Shared/Icon/Star-Black';

export enum Spacing {
  Normal,
  Medium,
  Wide,
}

export enum Size {
  Micro,
  Tiny,
  Normal,
  Large,
  Huge,
}

type PropType = {
  average: number;
  spacing: Spacing;
  size: Size;
} & StyledProps;

function getStyles(size: Size, spacing: Spacing) {
  let height;
  let width;
  let marginLeft;
  switch (size) {
    case Size.Micro:
      height = pixelsToUnit(10);
      width = pixelsToUnit(10);
      break;
    case Size.Tiny:
      height = pixelsToUnit(12.8);
      width = pixelsToUnit(13.8);
      break;
    case Size.Normal:
      height = pixelsToUnit(15);
      width = pixelsToUnit(15);
      break;
    case Size.Large:
      height = pixelsToUnit(20);
      width = pixelsToUnit(20);
      break;
    case Size.Huge:
      height = pixelsToUnit(24);
      width = pixelsToUnit(24);
      break;
    default:
      break;
  }
  switch (spacing) {
    case Spacing.Normal:
      marginLeft = pixelsToUnit(5);
      break;
    case Spacing.Medium:
      marginLeft = pixelsToUnit(10);
      break;
    case Spacing.Wide:
      marginLeft = pixelsToUnit(15);
      break;
    default:
      break;
  }
  return {
    height,
    width,
    ':not(:first-child)': {
      marginLeft,
    },
  };
}

export default styled((props: PropType) => {
  const RATINGS = [1, 2, 3, 4, 5];
  const floatPart = Number(props.average - Math.trunc(props.average)).toFixed(1);
  const parsedFloat = parseFloat(floatPart);
  const starStyles = getStyles(props.size, props.spacing);
  return (
    <Base css={props.compose()}>
      {RATINGS.map((rating) => {
        if (rating <= props.average) {
          return <StarBlack key={rating} css={starStyles} />;
        } else if (rating <= Math.ceil(props.average)) {
          if (parsedFloat < 0.4) {
            return <StarOutlineBlack key={rating} css={starStyles} />;
          } else if (parsedFloat < 0.8) {
            return <HalfStarBlack key={rating} css={starStyles} />;
          } else {
            return <StarBlack key={rating} css={starStyles} />;
          }
        } else {
          return <StarOutlineBlack key={rating} css={starStyles} />;
        }
      })}
    </Base>
  );
});

const Base = styled.div({
  whiteSpace: 'nowrap',
});
