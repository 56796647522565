import React from 'react';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import { pseudo } from '@glitz/core';
import { on } from '@avensia/scope';
import appearanceFunc, { AppearanceType } from '../appearance';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from '../Button';
import Ul from '../Generic/Ul';
import { Down } from '../Icon/Arrow';
import SelectType from './Select.type';
import { pixelsToUnit, concrete, white, transition, depth, sigma, small, General } from '../Style';

export { General as Variant } from '../Style';
export enum Appearance {
  Bare,
  Full,
}

const PLACEHOLDER = '-';
const Options = styled(Ul, {
  fontWeight: 'normal',
  position: 'absolute',
  top: '100%',
  right: 0,
  left: 0,
  transform: 'scaleY(0)',
  transformOrigin: 'center top',
  backgroundColor: white,
  listStyleType: 'none',
  ...transition({ property: 'transform' }),
  ...depth(),
});

const OptionLi = styled.li({
  position: 'relative',
  borderBottom: {
    width: pixelsToUnit(1),
    style: 'solid',
    color: '#e4d9eb',
  },
  padding: {
    y: pixelsToUnit(1.5),
  },
  ...pseudo(':last-child', {
    borderBottomStyle: 'none',
  }),
  ':hover': {
    backgroundColor: concrete,
  },
});

const Option = styled(Button, {
  fontSize: sigma,
  letterSpacing: pixelsToUnit(1.3),
  height: pixelsToUnit(40),
});

const OptionContent = styled.div({
  marginLeft: small,
  minWidth: '100%',
  textAlign: 'left',
});

const Content = styled.div({
  alignItems: 'center',
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'space-between',
  padding: { y: 0, x: pixelsToUnit(20) },
});

const IconDown = styled(Down, {
  width: pixelsToUnit(21),
  height: pixelsToUnit(12),
});

const IconUp = styled(IconDown, {
  transform: 'rotate(180deg)',
});

type PropType = StyledProps &
  Pick<
    React.HTMLAttributes<HTMLInputElement>,
    Exclude<keyof React.HTMLAttributes<HTMLInputElement>, 'type' | 'value' | 'className' | 'style'>
  > &
  SelectType & {
    variant?: General;
    appearance?: AppearanceType<Appearance | ButtonAppearance>;
    onChangeOption?: (value: string) => any;
  };

type StateType = {
  open?: boolean;
};

export default styled(
  class Select extends React.Component<PropType, StateType> {
    unsubscribeBodyClose: () => any;
    constructor(props: PropType) {
      super(props);
      this.state = {
        open: false,
      };
    }
    componentDidUpdate(prevProps: PropType, prevState: StateType) {
      const { open } = this.state;
      if (open !== prevState.open) {
        if (open) {
          this.unsubscribeBodyClose = on('click', () => this.setState({ open: false }));
        } else if (this.unsubscribeBodyClose) {
          this.unsubscribeBodyClose();
          delete this.unsubscribeBodyClose;
        }
      }
    }

    componentWillUnmount() {
      if (this.unsubscribeBodyClose) {
        this.unsubscribeBodyClose();
        delete this.unsubscribeBodyClose;
      }
    }

    toggle = () => this.setState({ open: !this.state.open });

    getContentWidth() {
      let count = 0;
      this.props.options.forEach((option) => {
        count = option.text.length > count ? option.text.length : count;
      });
      return `calc(${count} * (${sigma} / 2))`;
    }

    selectOption(value: string) {
      if (this.props.onChangeOption) {
        this.props.onChangeOption(value);
      }
      this.setState({ open: false });
    }

    render() {
      const selected =
        this.props.options.find((o) => o.value === this.props.value) ||
        this.props.options.find(
          (o) =>
            o.value.substring(o.value.lastIndexOf('/')) ===
            this.props.value.substring(this.props.value.lastIndexOf('/')),
        );

      const { compose, appearance = ButtonAppearance.Bare, variant, options } = this.props;
      const contentWidth = this.getContentWidth();
      const divStyle: Style = {
        alignItems: 'center',
        display: 'inline-flex',
        position: 'relative',
        maxWidth: '100%',
        textTransform: 'uppercase',
        zIndex: 1,
        ...(!appearanceFunc(appearance)(Appearance.Bare) && {
          backgroundColor: white,
        }),
        ...(appearanceFunc(appearance)(Appearance.Full) && {
          display: 'block',
        }),
      };

      return (
        <styled.Div css={compose(divStyle)} role="listbox">
          <Button appearance={appearance} variant={variant} onClick={this.toggle}>
            <Content css={{ minWidth: contentWidth }}>
              {selected ? selected.text : PLACEHOLDER}
              {this.state.open ? <IconUp /> : <IconDown />}
            </Content>
          </Button>
          <Options css={this.state.open && { transform: 'scaleY(1)' }}>
            {options.map((option) => (
              <OptionLi key={option.value}>
                <Option
                  variant={ButtonVariant.None}
                  appearance={[ButtonAppearance.Bare, ButtonAppearance.Full]}
                  onClick={() => this.selectOption(option.value)}
                  noRippleEffect
                >
                  <OptionContent>{option.text}</OptionContent>
                </Option>
              </OptionLi>
            ))}
          </Options>
        </styled.Div>
      );
    }
  },
);
