﻿import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { pixelsToUnit, white, sigma, kappa, minTinyMediaQuery } from 'Shared/Style';
import { Style } from '@glitz/type';

type PropType = StyledProps & {
  children?: React.ReactNode;
};

const Base = styled.div({
  fontSize: kappa,
  position: 'absolute',
  textTransform: 'uppercase',
  zIndex: 1,
  [minTinyMediaQuery]: {
    fontSize: sigma,
  },
});

const Badge = (props: PropType) => <Base css={props.compose()}>{props.children}</Base>;
const TriBadge = (props: PropType) => (
  <Base css={props.compose()}>
    <OuterWrapper>
      <InnerWrapper>{props.children}</InnerWrapper>
    </OuterWrapper>
  </Base>
);

export const CampaignBadge = styled(Badge, {
  color: (theme) => theme.campaignTextProductcard,
  position: 'absolute',
  top: 0,
  right: 0,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.7),
  textAlign: 'left',
  backgroundClip: 'text',
  margin: { xy: '0.5rem' },
  WebkitBackgroundClip: 'text',
  textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
});

const sharedStyle: Style = {
  borderRadius: '50%',
  color: white,
  margin: { xy: '0.5rem' },
  height: pixelsToUnit(32),
  lineHeight: pixelsToUnit(32),
  textAlign: 'center',
  width: pixelsToUnit(32),
  [minTinyMediaQuery]: {
    height: pixelsToUnit(40),
    lineHeight: pixelsToUnit(40),
    width: pixelsToUnit(40),
  },
};

const triStyle: Style = {
  height: pixelsToUnit(32),
  width: pixelsToUnit(32),
  lineHeight: pixelsToUnit(32),
  textAlign: 'center',
};

const OuterWrapper = styled.div({
  position: 'relative',
  height: '100%',
  width: '100%',
  border: {
    top: {
      color: (theme) => theme.discountBadgeColor,
      width: '75px',
      style: 'solid',
    },
    right: {
      color: 'transparent',
      width: '75px',
      style: 'solid',
    },
  },
});

const InnerWrapper = styled.div({
  position: 'absolute',
  top: pixelsToUnit(-75),
  left: pixelsToUnit(8),
  color: white,
  fontWeight: 'bold',
  fontSize: pixelsToUnit(16),
});

export const NewBadge = styled(Badge, {
  backgroundColor: (theme) => theme.badgeColor,
  ...sharedStyle,
});

export const DiscountBadge = styled(Badge, {
  backgroundColor: (theme) => theme.discountBadgeColor,
  ...sharedStyle,
});

export const DiscountTriBadge = styled(TriBadge, {
  ...triStyle,
});
