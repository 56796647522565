import { isBrowser, on } from '@avensia/scope';

let isBound = false;

export function bindInfiniteScroll(element: HTMLElement, callback: () => void, offset?: number) {
  if (isBrowser() && !isBound) {
    const listener = () => handleScroll(element, callback, offset);
    const unbindRootScroll = on('scroll', listener);
    isBound = true;
    return () => {
      unbindRootScroll();
      isBound = false;
    };
  }
}

function handleScroll(element: HTMLElement, callback: () => void, offset = 0) {
  const { clientHeight, scrollTop } = document.documentElement;
  const elementBottomPosition = element.offsetTop + element.offsetHeight;
  const scrollBottomPosition = (scrollTop ? scrollTop : window.pageYOffset) + clientHeight;

  if (elementBottomPosition <= scrollBottomPosition - offset) {
    callback();
  }
}
